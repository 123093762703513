import { Link } from '@remix-run/react'
import cn from 'classnames'

import Logo from '@/components/icons/Logo'
import { useCurrent } from '@/current'

type Props = {
  isFullWidth?: boolean
}

export default function SiteHeader({ isFullWidth }: Props) {
  const { theme, userSignedIn } = useCurrent()

  if (theme === 'legacy') {
    return <LegacySiteHeader isFullWidth={isFullWidth} />
  }

  return (
    <header className='relative mb-6 mt-4 bg-primary'>
      <div className='container flex max-w-7xl flex-col gap-y-3 px-3 sm:px-5'>
        <div className='grid w-full grid-cols-[auto_1fr] grid-rows-1 md:grid-rows-2 md:gap-3 lg:grid-rows-1'>
          <Link
            aria-label='Go to homepage'
            className='col-start-1 col-end-2 h-4 w-4 rounded-full text-primary lg:hidden'
            prefetch='intent'
            to='/'
          >
            <Logo height={32} width={32} />
          </Link>

          <div className='group col-start-2 col-end-3 flex items-center justify-end gap-1.5 lg:row-start-1 lg:gap-3'>
            {userSignedIn ? (
              <a href='https://app.planetscale.com' className='font-semibold text-orange'>
                Dashboard
              </a>
            ) : (
              <div className='flex items-center gap-1'>
                <a href='https://auth.planetscale.com/sign-in' className='font-semibold text-primary hover:text-orange'>
                  Sign in
                </a>
                <span className='text-decoration'>|</span>
                <a href='https://auth.planetscale.com/sign-up' className='font-semibold text-orange'>
                  Get started
                </a>
              </div>
            )}
          </div>

          <div className='col-start-1 col-end-3 flex items-center gap-x-3 lg:col-end-2 lg:row-start-1'>
            <Link
              aria-label='Go to homepage'
              className='col-start-1 col-end-2 hidden h-4 w-4 rounded-full text-primary lg:block'
              prefetch='intent'
              to='/'
            >
              <Logo height={32} width={32} />
            </Link>
            <nav aria-label='Main' data-orientation='horizontal' className='hidden items-center md:flex'>
              <SiteHeaderNavigation />
            </nav>
          </div>
        </div>

        <details className='md:hidden'>
          <summary>Navigation</summary>

          <nav className='dashed-box mt-1 p-3'>
            <SiteHeaderNavigation />
          </nav>
        </details>
      </div>
    </header>
  )
}

function SiteHeaderNavigation() {
  return (
    <ul className='flex flex-wrap gap-x-1 md:flex-nowrap'>
      <li>
        <Link className='hover:text-contrast font-semibold text-primary transition' prefetch='intent' to='/docs'>
          Documentation
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link
          className='hover:text-contrast font-semibold text-primary transition'
          prefetch='intent'
          to='/case-studies'
        >
          Case studies
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='hover:text-contrast font-semibold text-primary transition' prefetch='intent' to='/features'>
          Features
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='hover:text-contrast font-semibold text-primary transition' prefetch='intent' to='/blog'>
          Blog
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='hover:text-contrast font-semibold text-primary transition' prefetch='intent' to='/pricing'>
          Pricing
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='hover:text-contrast font-semibold text-primary transition' prefetch='intent' to='/contact'>
          Contact
        </Link>
      </li>
    </ul>
  )
}

export function LegacySiteHeader({ isFullWidth }: Props) {
  return (
    <>
      <header className='relative bg-primary py-2'>
        <div
          className={cn('flex items-center justify-between gap-1.5 text-sm', {
            'px-3 lg:px-4': isFullWidth,
            'container max-md:max-w-full xl:px-5': !isFullWidth
          })}
        >
          <div className='flex items-center gap-3'>
            <a href='/' className='h-4.5 w-4.5 rounded-full text-primary' aria-label='Go to homepage'>
              <Logo height={36} width={36} />
            </a>

            <div className='hidden lg:flex'>
              <nav aria-label='Main' data-orientation='horizontal'>
                <ul className='flex gap-x-3 lg:gap-x-3'>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/docs'>
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/case-studies'>
                      Case studies
                    </a>
                  </li>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/features'>
                      Features
                    </a>
                  </li>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/blog'>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/pricing'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='font-medium text-primary transition hover:text-orange' href='/contact'>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
